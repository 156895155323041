import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
//懒加载

//产品页面
//树脂
const Resin = () => import('../views/Products/Resin')
//bloom-740
const Printer = () => import('../views/Products/Printer')
//打印机参数
const Params = () => import('../views/Products/Printer/Params')
//主板
const MainBoard = () => import('../views/Products/MainBoard')
//主板/G1
const G1 = () => import('../views/Products/MainBoard/G1.vue')
//主板/G2
const G2 = () => import('../views/Products/MainBoard/G2.vue')
//主板/G3
const G3 = () => import('../views/Products/MainBoard/G3.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/products/resin',
    name: 'resin',
    component: Resin
  },
  {
    path: '/products/printer',
    name: 'printer',
    component:Printer,
  },
  {
    path: '/products/printer/Params',
    name: 'params',
    component: Params
  },
  {
    path: '/products/mainBoard',
    name: 'mainBoard',
    component: MainBoard,
  },
  {
    path: '/products/mainBoard/G1',
    name: 'G1',
    component: G1,
  }, 
  {
    path: '/products/mainBoard/G2',
    name: 'G2',
    component: G2,
  }, 
  {
    path: '/products/mainBoard/G3',
    name: 'G3',
    component: G3,
  }, 
  {
    path: '/products/software',
    name: 'software',
    component: () => import('../views/Products/Software'),
  }, 
  {
    path: '/products/software/SysRequirement',
    name: 'SysRequirement',
    component: () => import('../views/Products/Software/SysRequirement'),
  }, 
  {
    path: '/products/coreOctop',
    name: 'app',
    component: () => import('../views/OctoCore'),
  }, 
  {
    path: '/aboutUs/profile',
    name: 'profile',
    component: () => import('../views/AboutUs/Profile'),
  },
  {
    path: '/aboutUs/application',
    name: 'application',
    component: () => import('../views/AboutUs/Application'),
  },  
  {
    path: '/aboutUs/know',
    name: 'know',
    component: () => import('../views/AboutUs/Know'),
  }, 
  {
    path: '/aboutUs/belonging',
    name: 'belonging',
    component: () => import('../views/AboutUs/Belonging'),
  }, 
  {
    path: '/support/csrp',
    name: 'csrp',
    component: () => import('../views/Support/Csrp'), 
  }, 
  {
    path: '/support/faq',
    name: 'faq',
    component: () => import('../views/Support/FAQ'),
  },
  {
    path: '/support/warranty',
    name: 'warranty',
    component: () => import('../views/Support/Warranty'),
  }, 
  {
    path: '/support/download',
    name: 'download',
    component: () => import('../views/Support/DownloadInstall'),
  }, 
  {
    path: '/support/product',
    name: 'product',
    component: () => import('../views/Support/Product'),
  }, 
  {
    path: '/softFeedback',
    name: 'MagicPenSlicer',
    component: () => import('../views/SoftFeedback/MagicPenSlicer.vue')
  },
  {
    path: '/Dentalworks/FeedBack',
    name: 'Dentalworks',
    component: () => import('../views/SoftFeedback/Dentalworks.vue')
  }
]

const router = new VueRouter({ 
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
export default router
